
(function ($) {
    $(".transport-content").css("display", "none");
    $("#content-1").css("display", "block");

    $(".transport-title").on("click", function () {
        $(".transport-content").css("display", "none");
        $(".transport-title").removeClass("transport-active");
        $(this).addClass("transport-active");
        var e = $(this).attr('id');
        var a = e.split(/[- ]+/).pop();

        id = "#content-" + a;

        $(id).css("display", "block");
    });


})(jQuery);
