
(function ($) {
    $(".js-btn-scroll").on("click", function (e) {
        e.preventDefault();
        var a = $(this).attr('href');
        var slug = a.split(/[# ]+/).pop();
        var element = "#" + slug;

        $('html, body').animate({
            scrollTop: $(element).offset().top
        }, 1000);
    });
})(jQuery);
