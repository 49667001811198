var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyAPRNQPelZcC3hJnYfhmjZ49pJ4TBrDfQA';


(function () {
    if ($('#maps').length) {
        gmaps.load(function (google) {

            var map;

            function initialize() {
                var glat = 20.1583429;
                var glong = -87.4553561;

                var mapOptions = {
                    zoom: 18,
                    scrollwheel: false,
                    center: new google.maps.LatLng(glat, glong),
                    mapTypeId: 'hybrid',
                };
                map = new google.maps.Map(document.getElementById('maps'), mapOptions);

                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(glat, glong),
                    map: map
                });
            }
            google.maps.event.addDomListener(window, 'load', initialize);
        });
    }
})();
