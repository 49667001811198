(function($) {
$(".js-subnav").on("click", function() {
    $(this).toggleClass("open-btn");
    $(".sub-overview").toggleClass("open-sub");
});
$(".overview-title").on("click", function() {
    $(".sub-overview").toggleClass("open-sub", 300);
});

$(".js-godown").on("click", function(e) {
    e.preventDefault();
    $target = $(this).closest(".fullscreen");
    $("html, body").animate({
        scrollTop: $target.offset().top + $target.outerHeight()
    }, 750);
});


$(".article--button").on("click", function(e) {
    e.preventDefault();
    $target = $(this).parent();
    $target.toggleClass('article-closed');
});

$('.js-btn-popup').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: true
});

$('.popup-youtube').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false
});

$(window).load(function () {
    if ($('.home').length) {
        // retrieved this line of code from http://dimsemenov.com/plugins/magnific-popup/documentation.html#api
        $.magnificPopup.open({
            items: {
                src: 'http://www.youtube.com/watch?v=uErddSgES2w'
            },
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false

        // You may add options here, they're exactly the same as for $.fn.magnificPopup call
        // Note that some settings that rely on click event (like disableOn or midClick) will not work here
        }, 0);
    }
});



})(jQuery);
